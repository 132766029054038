import React from 'react'
import Projects from '../data/projects'
import { Link } from 'react-router-dom'

class Project extends React.Component {

  constructor(props) {
    super(props); 

    this.loadData = () => {
      let currentSlug = this.props.match.params.slug;
      let currentProject = Projects.find(p => p.slug === currentSlug);
      this.lastProject = Projects.length - 1
      this.index = Projects.findIndex(obj => obj.slug == currentProject.slug);
      this.name = Projects[this.index].name
      this.description = Projects[this.index].description
      this.tag = Projects[this.index].tag,
      this.hd = Projects[this.index].images,
      this.mobile = Projects[this.index].imagesMobile
      this.index == this.lastProject ? this.nextIndexSlug = 0 : this.nextIndexSlug = this.index + 1
      this.nextSlug = Projects[this.nextIndexSlug].slug
    }

    this.loadData();
    this.handleWindowSizeChange = this.handleWindowSizeChange.bind(this);

    this.state = {
      width: window.innerWidth,
      slug: props.match.params.slug,
      name: this.name,
      description: this.description,
      tag: this.tag,
      url: this.url,
      hd: this.hd,
      mobile: this.mobile,
      nextSlug: this.nextSlug
    }
  }

  updateState() {
    this.index == this.lastProject ? this.index = 0 : this.index += 1;
    this.index == this.lastProject ? this.nextIndexSlug = 0 : this.nextIndexSlug = this.index + 1;
    this.setState(prevState => {
      return {
        slug: this.props.match.params.slug,
        name: Projects[this.index].name,
        description: Projects[this.index].description,
        tag: Projects[this.index].tag,
        url: Projects[this.index].url,
        hd: Projects[this.index].images,
        mobile: Projects[this.index].imagesMobile,
        nextSlug: Projects[this.nextIndexSlug].slug
      }
    })
  }

  testState() {
    this.loadData();
    this.setState(() => {
      return {
        name: this.name,
        slug: this.props.match.params.slug,
        description: this.description,
        tag: this.tag,
        url: this.url,
        hd: this.hd,
        mobile: this.mobile,
        nextSlug: Projects[this.nextIndexSlug].slug
      }
    })
  }

  componentDidMount() {
    window.onpopstate = () => {
      this.testState();
    }
  }
  
  componentWillMount() {
    window.addEventListener('resize', this.handleWindowSizeChange);
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowSizeChange);
  }

  handleWindowSizeChange() {
    this.setState({ width: window.innerWidth })
  }
  render() {
    var hd = this.state.hd
    var mobile = this.state.mobile
    const { width } = this.state;
    const isMobile = width <= 768;
    if (isMobile) {
      return (
        <div className="content">
          <div className="page-header">
            <h1>{this.state.name}</h1>
            <p>{this.state.description}</p>
            <ul>
              <li>{this.state.tag}</li>
            </ul>
          </div>
          <div className="project">
            <div className="container">
              <div className="project-nav">
                <span className="next">
                  <Link onClick={this.updateState.bind(this)} to={this.state.nextSlug}>Next Project</Link>
                </span>
              </div>
              {
                mobile.map(function (counter, i) {
                  return <div className="project__section" key={i} ><img src={mobile[i]} /></div>
                })
              }
            </div>
          </div>
        </div>
      )
    } else {
      return (
        <div className="content">
          <div className="page-header">
            <h1>{this.state.name}</h1>
            <p>{this.state.description}</p>
            <ul>
              <li>{this.state.tag}</li>
            </ul>
          </div>
          <div className="project">
            <div className="container">
              <div className="project-nav">
                <span className="next">
                  <Link onClick={this.updateState.bind(this)} to={this.state.nextSlug}>Next Project</Link>
                </span>
              </div>
              {
                hd.map(function (counter, i) {
                  return <div className="project__section" key={i} ><img src={hd[i]} /></div>
                })
              }
              
            </div>
          </div>
        </div>
      )
    }
  }
}
export default Project