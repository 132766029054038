import React from 'react';

const Contact = () => (
	<div className="content">
		<div className="mask">
			<div className="output"></div>
		</div>
		<div className="page-header">
			<h1>Hello!</h1>
				<p>Feel free to drop us a line. We will contact you as soon as we can.</p>
			</div>
			<div className="contact">
				<form className="form" noValidate="true">
					<div className="text">
						<label>Whats your name?</label>
						<input type="text" name="name" id="name"/>
					</div>
					<div className="text">
						<label>Whats your e-mail?</label>
							<input type="email" name="email" id="email" />
					</div>
					<div className="text">
						<label>Write your message</label>
						<textarea name="message" rows="1" id="message"></textarea>
					</div>
					<div className="radio">
						<span>
							<svg className="send" version="1.1" id="Capa_1" x="0px" y="0px" viewBox="0 0 116 204.1" enableBackground="new 0 0 116 204.1">
								<path d="M91.8,204.1H18.7c-0.7,0-1.4-0.4-1.7-1c-0.4-0.6-0.3-1.4,0-2l17.4-28.1H22.7c-0.4,0-0.9-0.1-1.2-0.4L4,159.1
								c0,0-0.1-0.1-0.1-0.1c-0.1-0.1-0.2-0.2-0.3-0.3c-0.2-0.3-0.3-0.6-0.4-1c0-0.1,0-0.1,0-0.2V52c0-1.9,0.1-3.8,0.3-5.7
								c0-0.1,0-0.3,0-0.4c0-0.3,0.1-0.6,0.2-0.9c0.6-4.5,1.8-8.8,3.5-12.9c0-0.1,0.1-0.3,0.2-0.4c3.1-7.1,7.7-13.5,13.4-18.6
								c0.1-0.2,0.3-0.3,0.5-0.4C29.4,5.6,39.7,1,51.1,0.2c0,0,0.1,0,0.1,0c2.6-0.2,5.3-0.2,7.9,0c0,0,0.1,0,0.1,0C70.6,1,81,5.6,89.2,12.7
								c0.2,0.1,0.3,0.2,0.5,0.4c2.6,2.3,4.9,4.8,7,7.5c0.1,0.1,0.3,0.3,0.4,0.5c1.8,2.4,3.4,5,4.7,7.7l5.6-1.9c1-0.4,2.1,0.2,2.5,1.2
								l3,7.9c0.2,0.5,0.2,1.1-0.1,1.5c-0.2,0.5-0.6,0.9-1.1,1l-5.7,2c0.8,3.7,1.3,7.5,1.3,11.5v105.5c0,0.1,0,0.1,0,0.2
								c0,0.4-0.2,0.7-0.4,1c-0.1,0.1-0.2,0.2-0.3,0.3c0,0-0.1,0.1-0.1,0.1l-17.5,13.4c-0.3,0.3-0.8,0.4-1.2,0.4H76.1l17.4,28.1
								c0.4,0.6,0.4,1.4,0,2C93.2,203.7,92.6,204.1,91.8,204.1z M22.3,200.1h66l-16.9-27.2h-7.8c-2.2,2.2-5.2,3.5-8.4,3.5c0,0,0,0,0,0
								c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0s0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0l0,0c-1.2,0-2.4-0.2-3.4-0.5
								c-1.8-0.6-3.5-1.6-4.9-3h-7.7L22.3,200.1z M49,169.4c0.1,0.1,0.2,0.2,0.3,0.3c1.5,1.8,3.6,2.8,5.9,2.8c0,0,0,0,0,0c0,0,0,0,0,0
								c0,0,0,0,0,0c1.1,0,2.1-0.2,3-0.6c2.8-1.2,4.7-3.9,4.7-7.1v-50.9H47.5v43.7c0,0.2,0,0.4-0.1,0.5v6.7C47.4,166.4,48,168.1,49,169.4z
								M72.5,168.9H87l12.3-9.4H66.9v5.2c0,1.5-0.3,2.9-0.8,4.3H72.5z M38,168.9h6.2c-0.5-1.3-0.8-2.8-0.8-4.3v-5.2H11.1l12.3,9.4
								L38,168.9L38,168.9z M67,155.5h36.2V58.1H65.9c4.1,3.2,6.7,8.2,6.7,13.7v32.7l0,0v0l0,0c0,0,0,0,0,0c0,0,0,0,0,0
								c0,0.5-0.2,0.9-0.4,1.2l-5.2,6.8L67,155.5L67,155.5z M7.2,155.5h36.2v-42.9l-5.2-6.7c-0.3-0.3-0.4-0.7-0.4-1.2l0,0c0,0,0,0,0,0
								c0,0,0,0,0,0V71.8c0-5.6,2.6-10.6,6.7-13.7H7.2V155.5z M46.3,109.8h17.9l2.4-3.2H43.9L46.3,109.8z M41.8,102.6h26.8V71.8
								c0-7.4-6-13.4-13.4-13.4c-7.4,0-13.4,6-13.4,13.4V102.6z M7.2,54.1h96V52c0-1.4-0.1-2.8-0.2-4.1H73.2c-1.1,0-2-0.9-2-2V22
								c0-1.1,0.9-2,2-2h17.8c-1.2-1.3-2.4-2.5-3.7-3.7H77.2c-0.4,0-0.8-0.1-1.1-0.3L63.1,7.3l2.4,6.3c0.2,0.6,0.2,1.3-0.2,1.8
								c-0.4,0.5-1,0.9-1.6,0.9H45.8c-0.7,0-1.3-0.3-1.7-0.9c-0.4-0.6-0.4-1.3-0.1-1.9l2.3-5.2L36.1,16c-0.3,0.3-0.8,0.4-1.2,0.4H23.1
								c-4.4,4-8.1,8.8-10.8,14.3h17.2c1.1,0,2,0.9,2,2v13.2c0,1.1-0.9,2-2,2H7.4c-0.1,1.4-0.2,2.7-0.2,4.1V54.1z M75.2,43.9h27.3
								c-1.3-7.3-4.2-14-8.3-19.8h-19L75.2,43.9L75.2,43.9z M7.9,43.9h19.6v-9.2h-17C9.3,37.6,8.4,40.7,7.9,43.9z M103.3,32.4
								c0.6,1.4,1.1,2.8,1.5,4.2l3.5-1.2l-1.6-4.2L103.3,32.4z M77.8,12.4h4.4c-4.3-3-9.1-5.2-14.3-6.6L77.8,12.4z M48.9,12.4h11.8
								l-3.2-8.3C56,4,54.3,4,52.7,4.1L48.9,12.4z M28.1,12.4h6.1l9.3-6.9C38,6.8,32.8,9.2,28.1,12.4z M55.2,87.9
								c-5.8,0-10.6-4.7-10.6-10.6s4.7-10.6,10.6-10.6s10.6,4.7,10.6,10.6C65.8,83.2,61.1,87.9,55.2,87.9z M55.2,70.8c-3.6,0-6.6,3-6.6,6.6
								s3,6.6,6.6,6.6c3.6,0,6.6-3,6.6-6.6S58.9,70.8,55.2,70.8z M60.9,47.9H40.2c-1.1,0-2-0.9-2-2V32.6c0-1.1,0.9-2,2-2h20.8
								c1.1,0,2,0.9,2,2v13.2C62.9,47,62,47.9,60.9,47.9z M42.2,43.9h16.8v-9.2H42.2V43.9z M88.5,42.2c-4.5,0-8.2-3.7-8.2-8.2
								s3.7-8.2,8.2-8.2c4.5,0,8.2,3.7,8.2,8.2S93.1,42.2,88.5,42.2z M88.5,29.7c-2.3,0-4.2,1.9-4.2,4.3s1.9,4.2,4.2,4.2s4.2-1.9,4.2-4.2
								S90.9,29.7,88.5,29.7z"/>
							</svg>
							Are you a robot?</span>
							<input type="radio" id="yes" name="bot" value="yes" className="checked" />
							<label htmlFor="yes">Yes</label>
							<input type="radio" id="no" name="bot" value="no" />
							<label htmlFor="no">No</label>
							<input type="radio" id="wish" name="bot" value="wish" />
							<label htmlFor="wish">I wish</label>
						</div>
						<div className="clearfix">
							<button className="submit">
								<span className="morph-shape" data-morph-active="M286,113c0,0-68.8,9-136,9c-78.2,0-137-9-137-9S3,97.198,3,62.5C3,33.999,13,12,13,12S72,2,150,2c85,0,136,10,136,10s11,17.598,11,52C297,96.398,286,113,286,113z">
									<svg width="100%" height="100%" fill="#ef2b63" viewBox="0 0 300 125" preserveAspectRatio="none">
										<path d="M286,113c0,0-68.8,0-136,0c-78.2,0-137,0-137,0s0-15.802,0-50.5C13,33.999,13,12,13,12s59,0,137,0c85,0,136,0,136,0s0,17.598,0,52C286,96.398,286,113,286,113z"/>
									</svg>
								</span>
								<span className="submit-text">Send</span>
							</button>
						</div>
				</form>
		</div>
	</div>
);


export default Contact
