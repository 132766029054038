import React from 'react';
import { Link } from 'react-router-dom'

const Home = () => (
  <div className="home">
        <h1>Pixels Beautifully Crafted</h1>
        <div className="home__big-tagline">
		<svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 719 69.7">
			<g>
			    <path className="p1" stroke="#000000" strokeMiterlimit="10" d="M2,52.1v-5.1l2.1-0.2c1.2-0.1,1.8-0.7,1.8-1.8V16l-3.6-0.2v-5.3h17.9
			        c4.7,0,8.3,1.1,10.9,3.3c2.6,2.2,3.9,5.5,3.9,9.9c0,4.4-1.5,7.8-4.4,10.2c-3,2.4-6.9,3.7-11.8,3.7h-5.3v9.1l6,0.2v5.3H2z
			         M13.5,31.5H18c6.1,0,9.1-2.5,9.1-7.5c0-3-0.8-5-2.3-6c-1.6-1-4.1-1.5-7.5-1.5h-3.8V31.5z"/>
			    <path className="p2" stroke="#000000" strokeMiterlimit="10" d="M50.4,20.6v26.3l3.6,0.2v5H39v-4.8l2.1-0.2c1.2-0.1,1.8-0.8,1.8-2V27.8
			        c0-0.7-0.1-1.2-0.3-1.4c-0.2-0.3-0.6-0.4-1.2-0.4l-2.2-0.1v-5.2H50.4z M42.5,15.2c-0.9-0.9-1.3-2-1.3-3.3c0-1.3,0.4-2.5,1.3-3.4
			        c0.9-0.9,2-1.3,3.4-1.3c1.4,0,2.6,0.4,3.5,1.3c0.9,0.9,1.3,2,1.3,3.4c0,1.3-0.4,2.4-1.3,3.3c-0.9,0.9-2.1,1.3-3.5,1.3
			        C44.5,16.5,43.3,16.1,42.5,15.2z"/>
			    <path className="p3" stroke="#000000" strokeMiterlimit="10" d="M75.5,45.5l-3.8-5l-5.2,6.5l2.9,0.2v5h-13v-4.9l1.9-0.2c1-0.1,1.8-0.6,2.5-1.5
			        l7.2-9l-7.7-10.6l-3.2-0.4v-4.8h14.6v4.9l-1.8,0.1c-0.5,0-0.8,0.2-1,0.6c-0.1,0.3-0.1,0.7,0.2,1.1l3.6,4.8l4.8-6.3L75,25.6v-5h12.7
			        v4.9l-1.9,0.1c-0.9,0-1.7,0.6-2.5,1.6l-6.9,8.7l8.2,10.9l3.2,0.4v4.9H72.9v-4.9l1.9-0.1c0.5,0,0.9-0.2,1-0.5
			        C75.9,46.2,75.8,45.9,75.5,45.5z"/>
			    <path className="p4" stroke="#000000" strokeMiterlimit="10" d="M106.7,19.7c3.1,0,5.6,0.8,7.5,2.3c1.8,1.5,2.8,3.7,2.8,6.4
			        c0,1.8-0.4,3.5-1.2,4.9c-0.8,1.4-1.8,2.5-2.9,3.3c-1.2,0.8-2.6,1.4-4.3,1.9c-2.8,0.8-6,1.3-9.5,1.3c0.1,2.2,0.8,4,2.1,5.4
			        c1.3,1.4,3.2,2,5.8,2s5.2-0.9,7.8-2.8l2.4,5.1c-0.8,0.8-2.2,1.5-4.2,2.3c-2,0.8-4.2,1.2-6.8,1.2c-5.1,0-8.8-1.4-11.2-4.2
			        c-2.4-2.8-3.6-6.7-3.6-11.6c0-4.9,1.4-9.1,4.1-12.4C98,21.4,101.8,19.7,106.7,19.7z M103.9,34c1.5-0.3,2.9-0.9,4.2-1.8
			        c1.3-0.9,1.9-2.1,1.9-3.4c0-2.5-1.3-3.8-3.8-3.8c-2.3,0-4.1,0.9-5.4,2.8c-1.3,1.9-1.9,4.1-2.1,6.7C100.7,34.4,102.3,34.3,103.9,34z
			        "/>
			    <path className="p5" stroke="#000000" strokeMiterlimit="10" d="M132.5,7.5v39.5l3.6,0.2v5h-14.9v-4.8l2.1-0.2c1.2-0.1,1.8-0.8,1.8-2V14.3
			        c0-0.9-0.5-1.4-1.6-1.5l-2.2-0.1V7.5H132.5z"/>
			    <path className="p6" stroke="#000000" strokeMiterlimit="10" d="M156.4,25.4c-1-0.4-2.2-0.6-3.6-0.6c-1.4,0-2.6,0.3-3.4,1
			        c-0.9,0.6-1.3,1.4-1.3,2.4s0.2,1.7,0.5,2.2c0.3,0.5,0.8,1,1.5,1.3c1,0.5,2.3,1,3.8,1.4c1.5,0.4,2.5,0.7,3.3,0.9
			        c0.7,0.2,1.6,0.6,2.7,1.2c1.1,0.5,1.9,1.1,2.4,1.8c1.5,1.5,2.2,3.5,2.2,5.9c0,3.1-1.1,5.6-3.4,7.4c-2.3,1.8-5.2,2.7-8.7,2.7
			        c-5.1,0-8.9-0.6-11.5-1.9v-8.7l5.6-0.4v3c0,1.8,1.8,2.8,5.3,2.8c3.5,0,5.3-1.3,5.3-3.8c0-0.9-0.3-1.7-0.9-2.3
			        c-0.6-0.6-1.2-1-1.8-1.2c-0.6-0.2-1.3-0.4-2.1-0.6c-0.8-0.2-1.6-0.4-2.4-0.6c-0.8-0.2-1.6-0.5-2.5-0.8c-0.9-0.4-1.9-0.9-3-1.5
			        c-2.1-1.4-3.2-3.7-3.2-6.9c0-3.2,1.1-5.7,3.4-7.5c2.3-1.8,5.2-2.6,8.7-2.6s6.9,0.8,10.4,2.5v7.5l-5.6,0.4v-2.6
			        C157.8,26.5,157.3,25.8,156.4,25.4z"/>
			    <path className="p7" stroke="#000000" strokeMiterlimit="10" d="M181.1,10.5h17.7c9,0,13.5,3.5,13.5,10.5c0,3.8-1.9,6.8-5.8,8.8
			        c2.5,0.6,4.4,1.8,5.8,3.4c1.4,1.7,2.1,3.8,2.1,6.5c0,4.1-1.4,7.1-4.1,9.3s-6.7,3.2-11.9,3.2h-17.6v-5.1l2.1-0.2
			        c1.2-0.1,1.8-0.7,1.8-1.8V16l-3.6-0.2V10.5z M192.3,16.3v11.3h4.6c2.6,0,4.5-0.5,5.7-1.6c1.2-1.1,1.8-2.6,1.8-4.5
			        c0-1.9-0.6-3.3-1.8-4.1c-1.2-0.8-3-1.2-5.3-1.2H192.3z M192.3,33.2v13.1h5.9c5.4,0,8.1-2.2,8.1-6.6c0-2.1-0.7-3.7-2-4.8
			        c-1.4-1.1-3.3-1.7-5.8-1.7H192.3z"/>
			    <path className="p8" stroke="#000000" strokeMiterlimit="10" d="M234.2,19.7c3.1,0,5.6,0.8,7.5,2.3c1.8,1.5,2.8,3.7,2.8,6.4
			        c0,1.8-0.4,3.5-1.2,4.9c-0.8,1.4-1.8,2.5-2.9,3.3c-1.2,0.8-2.6,1.4-4.3,1.9c-2.8,0.8-6,1.3-9.5,1.3c0.1,2.2,0.8,4,2.1,5.4
			        c1.3,1.4,3.2,2,5.8,2s5.2-0.9,7.8-2.8l2.4,5.1c-0.8,0.8-2.2,1.5-4.2,2.3c-2,0.8-4.2,1.2-6.8,1.2c-5.1,0-8.8-1.4-11.2-4.2
			        c-2.4-2.8-3.6-6.7-3.6-11.6c0-4.9,1.4-9.1,4.1-12.4C225.5,21.4,229.3,19.7,234.2,19.7z M231.3,34c1.5-0.3,2.9-0.9,4.2-1.8
			        c1.3-0.9,1.9-2.1,1.9-3.4c0-2.5-1.3-3.8-3.8-3.8c-2.3,0-4.1,0.9-5.4,2.8c-1.3,1.9-1.9,4.1-2.1,6.7C228.1,34.4,229.8,34.3,231.3,34z
			        "/>
			    <path className="p9" stroke="#000000" strokeMiterlimit="10" d="M276.9,21.9v23.3c0,0.7,0.1,1.1,0.3,1.4c0.2,0.3,0.6,0.4,1.2,0.4l2,0.1v5h-10
			        v-3.6l-0.2-0.1c-2.1,3-4.9,4.5-8.6,4.5c-4.3,0-7.4-1.4-9.5-4.1c-2-2.7-3.1-6.4-3.1-11.1c0-5.7,1.4-10.1,4.1-13.3
			        c2.8-3.2,6.9-4.8,12.4-4.8C269.3,19.7,273,20.4,276.9,21.9z M269.6,42.2V26c-1.2-0.5-2.8-0.8-4.8-0.8c-2.8,0-4.8,1.1-6.1,3.4
			        c-1.3,2.3-1.9,5.2-1.9,9c0,6.8,2.2,10.1,6.5,10.1c1.8,0,3.3-0.6,4.5-1.7C269,44.9,269.6,43.6,269.6,42.2z"/>
			    <path className="p10" stroke="#000000" strokeMiterlimit="10" d="M316.2,47.2v4.9h-10.1v-4.1c-2.3,3.3-5.7,4.9-10.1,4.9c-6.5,0-9.7-3.6-9.7-10.8
			        V27.7c0-1.1-0.5-1.7-1.6-1.8l-2-0.1v-5.1h11v19.9c0,2.2,0.3,3.9,0.9,5s1.9,1.7,3.9,1.7c2,0,3.5-0.6,4.7-1.8
			        c1.2-1.2,1.8-2.7,1.8-4.4V27.8c0-0.7-0.1-1.1-0.3-1.4c-0.2-0.3-0.6-0.4-1.2-0.5l-2-0.1v-5.1h11v24.6c0,0.7,0.1,1.1,0.3,1.3
			        c0.2,0.2,0.6,0.4,1.3,0.5L316.2,47.2z"/>
			     <path className="p11" stroke="#000000" strokeMiterlimit="10" d="M325.1,14h4.1v6.6h8.3l-0.6,5.3h-7.7v16.5c0,1.8,0.3,3.1,1,3.9
			        c0.7,0.7,1.7,1.1,3.1,1.1c1.4,0,2.8-0.4,4.2-1.3l1.9,4.8c-2.1,1.5-4.7,2.2-8,2.2c-1.9,0-3.5-0.2-4.8-0.7c-1.3-0.5-2.3-1-2.9-1.6
			        c-0.6-0.6-1.1-1.4-1.4-2.6s-0.5-2-0.6-2.7c0-0.6-0.1-1.6-0.1-2.8V25.9h-4.4l0.6-4.6c2-0.2,3.4-0.9,4.4-2.1
			        C323.4,18,324.3,16.3,325.1,14z"/>
			    <path className="p12" stroke="#000000" strokeMiterlimit="10" d="M353.1,20.6v26.3l3.6,0.2v5h-14.9v-4.8l2.1-0.2c1.2-0.1,1.8-0.8,1.8-2V27.8
			        c0-0.7-0.1-1.2-0.3-1.4c-0.2-0.3-0.6-0.4-1.2-0.4l-2.2-0.1v-5.2H353.1z M345.2,15.2c-0.9-0.9-1.3-2-1.3-3.3c0-1.3,0.4-2.5,1.3-3.4
			        c0.9-0.9,2-1.3,3.4-1.3c1.4,0,2.6,0.4,3.5,1.3c0.9,0.9,1.3,2,1.3,3.4c0,1.3-0.4,2.4-1.3,3.3c-0.9,0.9-2.1,1.3-3.5,1.3
			        C347.2,16.5,346.1,16.1,345.2,15.2z"/>
			    <path className="p13" stroke="#000000" strokeMiterlimit="10" d="M364.3,45.1V25.9h-5.4v-5.3h5.4v-2.4c0-4.1,0.9-7,2.8-8.8
			        c1.8-1.7,4.3-2.6,7.7-2.6s6.4,0.7,9,2.2l-1.8,5c-2-1-3.9-1.6-5.6-1.6c-1.8,0-3,0.4-3.6,1.3c-0.7,0.9-1,2.2-1,3.9v2.9h9.3v5.3h-9.3
			        v21l5.3,0.3v5h-16.6v-4.8l2.1-0.2c0.6-0.1,1.1-0.3,1.4-0.5C364.1,46.4,364.3,45.9,364.3,45.1z"/>
			     <path className="p14" stroke="#000000" strokeMiterlimit="10" d="M416.7,47.2v4.9h-10.1v-4.1c-2.3,3.3-5.7,4.9-10.1,4.9c-6.5,0-9.7-3.6-9.7-10.8
			        V27.7c0-1.1-0.5-1.7-1.6-1.8l-2-0.1v-5.1h11v19.9c0,2.2,0.3,3.9,0.9,5s1.9,1.7,3.9,1.7c2,0,3.5-0.6,4.7-1.8
			        c1.2-1.2,1.8-2.7,1.8-4.4V27.8c0-0.7-0.1-1.1-0.3-1.4c-0.2-0.3-0.6-0.4-1.2-0.5l-2-0.1v-5.1h11v24.6c0,0.7,0.1,1.1,0.3,1.3
			        c0.2,0.2,0.6,0.4,1.3,0.5L416.7,47.2z"/>
			    <path className="p15" stroke="#000000" strokeMiterlimit="10" d="M431.7,7.5v39.5l3.6,0.2v5h-14.9v-4.8l2.1-0.2c1.2-0.1,1.8-0.8,1.8-2V14.3
			        c0-0.9-0.5-1.4-1.6-1.5l-2.2-0.1V7.5H431.7z"/>
			    <path className="p16" stroke="#000000" strokeMiterlimit="10" d="M450.4,7.5v39.5l3.6,0.2v5H439v-4.8l2.1-0.2c1.2-0.1,1.8-0.8,1.8-2V14.3
			        c0-0.9-0.5-1.4-1.6-1.5l-2.2-0.1V7.5H450.4z"/>
			    <path className="p17" stroke="#000000" strokeMiterlimit="10" d="M480.9,50.9v-4.8c-2.3,3.3-5.7,4.9-10.1,4.9c-6.5,0-9.7-3.6-9.7-10.8V27.7
			        c0-1.1-0.5-1.7-1.6-1.8l-2-0.1v-5.1h11v18c0,2.2,0.3,3.9,0.9,5s1.9,1.7,3.9,1.7c2,0,3.5-0.6,4.7-1.8c1.2-1.2,1.8-2.7,1.8-4.4V27.8
			        c0-0.7-0.1-1.2-0.3-1.5c-0.2-0.3-0.6-0.4-1.2-0.4l-2-0.1v-5.1h11v30.3c0,5.1-1.2,8.7-3.7,11.1c-2.4,2.3-6.1,3.5-11.1,3.5
			        c-4.9,0-8.8-0.7-11.6-2.1v-8.7l6-0.5v3c0,1.1,0.5,1.9,1.6,2.3c1,0.4,2.5,0.6,4.3,0.6C478.2,60.2,480.9,57.1,480.9,50.9z"/>
			    <path className="p18" stroke="#000000" strokeMiterlimit="10" d="M531.5,17.1c-0.5-0.8-2.2-1.2-5.1-1.2c-3.9,0-6.8,1.3-8.8,3.9
			        c-2,2.6-3,6.4-3,11.4c0,10.3,3.9,15.5,11.6,15.5c0.1,0,0.7,0,1.7,0c1,0,1.9-0.1,2.8-0.4c0.9-0.3,1.4-0.6,1.6-0.8
			        c0.2-0.3,0.3-0.8,0.3-1.4v-5l6.4,0.4v10.8c-3.3,1.7-7.4,2.6-12.4,2.6c-6.4,0-11.3-1.8-14.6-5.4c-3.4-3.6-5-8.9-5-16
			        c0-3.9,0.6-7.3,1.7-10.3c1.1-2.9,2.7-5.2,4.6-6.8c3.8-3.1,8.2-4.7,13.4-4.7c4.2,0,8.1,0.9,11.8,2.6V23l-6.4,0.4v-4.9
			        C531.7,17.9,531.6,17.4,531.5,17.1z"/>
			    <path className="p19" stroke="#000000" strokeMiterlimit="10" d="M547.8,45.1V27.8c0-0.7-0.1-1.1-0.3-1.4c-0.2-0.3-0.6-0.4-1.2-0.5l-2.6-0.2v-5.1
			        h10.6v4.7c0.8-1.6,1.9-3,3.5-4c1.6-1.1,3.4-1.6,5.5-1.6c2.1,0,4,0.5,5.9,1.4v9.3l-5.8,0.4V28c0-0.8-0.2-1.4-0.6-1.6
			        c-0.5-0.2-1-0.3-1.7-0.3c-1.7,0-3,0.6-4.1,1.8s-1.6,2.7-1.6,4.5v14.5l5.4,0.3v4.9h-16.8v-4.8l2.1-0.2c0.6-0.1,1.1-0.3,1.4-0.5
			        C547.7,46.4,547.8,45.9,547.8,45.1z"/>
			    <path className="p20" stroke="#000000" strokeMiterlimit="10" d="M601,21.9v23.3c0,0.7,0.1,1.1,0.3,1.4c0.2,0.3,0.6,0.4,1.2,0.4l2,0.1v5h-10v-3.6
			        l-0.2-0.1c-2.1,3-4.9,4.5-8.6,4.5c-4.3,0-7.4-1.4-9.5-4.1c-2-2.7-3.1-6.4-3.1-11.1c0-5.7,1.4-10.1,4.1-13.3
			        c2.8-3.2,6.9-4.8,12.4-4.8C593.4,19.7,597.1,20.4,601,21.9z M593.7,42.2V26c-1.2-0.5-2.8-0.8-4.8-0.8c-2.8,0-4.8,1.1-6.1,3.4
			        c-1.3,2.3-1.9,5.2-1.9,9c0,6.8,2.2,10.1,6.5,10.1c1.8,0,3.3-0.6,4.5-1.7C593.1,44.9,593.7,43.6,593.7,42.2z"/>
			    <path className="p21" stroke="#000000" strokeMiterlimit="10" d="M612.3,45.1V25.9h-5.4v-5.3h5.4v-2.4c0-4.1,0.9-7,2.8-8.8
			        c1.8-1.7,4.3-2.6,7.7-2.6s6.4,0.7,9,2.2l-1.8,5c-2-1-3.9-1.6-5.6-1.6c-1.8,0-3,0.4-3.6,1.3c-0.7,0.9-1,2.2-1,3.9v2.9h9.3v5.3h-9.3
			        v21l5.3,0.3v5h-16.6v-4.8l2.1-0.2c0.6-0.1,1.1-0.3,1.4-0.5C612.2,46.4,612.3,45.9,612.3,45.1z"/>
			    <path className="p22" stroke="#000000" strokeMiterlimit="10" d="M638.5,14h4.1v6.6h8.3l-0.6,5.3h-7.7v16.5c0,1.8,0.3,3.1,1,3.9
			        c0.7,0.7,1.7,1.1,3.1,1.1c1.4,0,2.8-0.4,4.2-1.3l1.9,4.8c-2.1,1.5-4.7,2.2-8,2.2c-1.9,0-3.5-0.2-4.8-0.7c-1.3-0.5-2.3-1-2.9-1.6
			        c-0.6-0.6-1.1-1.4-1.4-2.6s-0.5-2-0.6-2.7c0-0.6-0.1-1.6-0.1-2.8V25.9h-4.4l0.6-4.6c2-0.2,3.4-0.9,4.4-2.1
			        C636.7,18,637.7,16.3,638.5,14z"/>
			    <path className="p23" stroke="#000000" strokeMiterlimit="10" d="M670.9,19.7c3.1,0,5.6,0.8,7.5,2.3c1.8,1.5,2.8,3.7,2.8,6.4
			        c0,1.8-0.4,3.5-1.2,4.9c-0.8,1.4-1.8,2.5-2.9,3.3c-1.2,0.8-2.6,1.4-4.3,1.9c-2.8,0.8-6,1.3-9.5,1.3c0.1,2.2,0.8,4,2.1,5.4
			        c1.3,1.4,3.2,2,5.8,2s5.2-0.9,7.8-2.8l2.4,5.1c-0.8,0.8-2.2,1.5-4.2,2.3c-2,0.8-4.2,1.2-6.8,1.2c-5.1,0-8.8-1.4-11.2-4.2
			        c-2.4-2.8-3.6-6.7-3.6-11.6c0-4.9,1.4-9.1,4.1-12.4C662.2,21.4,666,19.7,670.9,19.7z M668,34c1.5-0.3,2.9-0.9,4.2-1.8
			        c1.3-0.9,1.9-2.1,1.9-3.4c0-2.5-1.3-3.8-3.8-3.8c-2.3,0-4.1,0.9-5.4,2.8c-1.3,1.9-1.9,4.1-2.1,6.7C664.8,34.4,666.5,34.3,668,34z"
			        />
			    <path className="p24" stroke="#000000" strokeMiterlimit="10" d="M701.2,19.7c1.8,0,3.5,0.3,5.1,0.8v-6.3c0-0.9-0.5-1.4-1.6-1.5l-2.7-0.2V7.5h11.6
			        v38.1c0,1,0.6,1.4,1.6,1.4l2.2,0.1v5h-10.1v-3.7l-0.2-0.1c-1.9,3-4.7,4.6-8.5,4.6c-4.8,0-8.1-1.6-10-4.8c-1.8-2.9-2.6-6.4-2.6-10.6
			        c0-5.4,1.3-9.8,4-13S696.4,19.7,701.2,19.7z M706.3,42.4V26.1c-1.5-0.7-3.1-1-4.8-1c-2.8,0-4.8,1.1-6,3.3c-1.3,2.2-1.9,5-1.9,8.5
			        c0,7,2.3,10.5,6.8,10.5c1.7,0,3.1-0.5,4.3-1.5C705.8,45,706.3,43.8,706.3,42.4z"/>
			</g>
		</svg>
	   </div>
        <h2>A studio of web designers and digital artists</h2>
        <Link to="/portfolio" className="home__view">View our portfolio</Link>

        <div className="home__share">
          <a target="_blank" href="https://www.facebook.com/pixelatelierstudio"><i className="fa fa-facebook"></i></a>
          <a target="_blank" href="https://www.instagram.com/pixelatelieragency/"><i className="fa fa-instagram"></i></a>
        </div>
      </div>
)

export default Home
